import React, { Component } from "react";
import InputSearch from "../common/inputsearch";
import { findResident } from "../../services/residentService";
import { Link } from "react-router-dom";
import uniqId from "uniqid";
import Select from "../../components/common/select";

import { differenceInDays, parse, parseISO } from "date-fns";
import { isNumber } from "joi-browser";
import { CiCircleChevRight } from "react-icons/ci";
function checkDate(date) {
  const inputDate = new Date(date);
  const now = new Date();
  const currentDay = now.getDay();
  const lastSunday = new Date(now);
  lastSunday.setDate(now.getDate() - currentDay);
  lastSunday.setHours(0, 0, 0, 0);

  return inputDate >= lastSunday && inputDate <= now;
}
function parseAnyDate(date) {
  const parsedDate = new Date(date);

  // If the date is already a Date object, return it directly
  if (date instanceof Date) {
    return date;
  }

  // If the date is an ISO string or a format recognized by the Date constructor
  else if (!isNaN(parsedDate)) {
    return parsedDate;
  }

  // Array of custom date formats to attempt parsing
  const formats = [
    "yyyy-MM-dd",
    "MM/dd/yyyy",
    "dd/MM/yyyy",
    "yyyy/MM/dd",
    "MM-dd-yyyy",
    "dd-MM-yyyy",
    "yyyy.MM.dd",
    "MM.dd.yyyy",
    "dd.MM.yyyy",
  ];

  // Attempt to parse the date using custom formats
  return parseISO(date);
}

function getDaysBetweenDates(date1, date2) {
  const parsedDate1 = parseAnyDate(date1);
  const parsedDate2 = parseAnyDate(date2);
  return Math.abs(differenceInDays(parsedDate2, parsedDate1));
}
function dateShower(date) {
  if (date) {
    let dateString = date.slice(0, 10);
    dateString = dateString.split("-");
    return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
  }
}
class FindResident extends Component {
  state = {
    query: "",
    filter: 2,
    sort: 2,
    page: 1,
    noMorePages: false,
    search: false,
    res: [],
  };

  async componentDidMount() {
    try {
      let { data } = await findResident(
        this.state.query,
        this.state.filter,
        this.state.sort,
        this.state.page
      );
      console.log(data);
      // if (this.state.data.ssn && !this.state.data.name) {s
      //   console.log("filter");
      // data.res = data.res.filter((res) => res?.IsActive === true);
      // }
      data.res.forEach((resident, index) => {
        let adms = [];
        data.adm.forEach((add) => {
          // if(add.ResID === resident.ResID) count = count+1
          if (add.ResID === resident.ResID) {
            adms.push(add);
          }
        });
        if (adms.length > 0) {
          let adm = adms[0];
          let days = getDaysBetweenDates(
            adm.GuestInDate ? adm.GuestInDate : new Date(),
            adm.DateOut ? adm.DateOut : new Date()
          );
          data.res[index].daysInProgram = days;
          data.res[index].GuestInDate = adm.GuestInDate;
        } else {
          data.res[index].daysInProgram = 0;
        }
        data.res[index].admissionCount = data.admCount[resident.ResID];
        let infs = [];
        data?.inf?.forEach((inf) => {
          // if(inf.ResID === resident.ResID) count = count+1
          if (inf.ResID === resident.ResID) {
            infs.push(inf);
          }
        });
        if (infs.length > 0) {
          let infFilter = infs.filter((inf) => checkDate(inf.Date));
          let infCount = infFilter.reduce((acc, val) => acc + val.Points, 0);
          console.log(infCount);
          data.res[index].infractionPoints = infCount;
        } else {
          data.res[index].infractionPoints = 0;
        }
      });
      this.setState({
        res: data.res,
        adm: data.adm,
        search: false,
        noMorePages: data.length < 20,
        totalRecords: data.totalRecords,
      });
    } catch (ex) {
      console.log(ex);
      // toast.error(ex.message);
    }
  }

  handleChange = ({ currentTarget: input }) => {
    let query = this.state.query;
    query = input.value;
    this.setState({ query, search: false, page: 1 });
  };
  formatNumber = (inputValue) => {
    let numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = "";

    for (let i = 0; i < numericValue.length; i++) {
      if (i === 3 || i === 5) {
        formattedValue += "-";
      }
      formattedValue += numericValue[i];
    }

    if (formattedValue.length > 11) {
      formattedValue = formattedValue.slice(0, 11);
    }
    return formattedValue;
  };
  handleChangeSearch = ({ currentTarget: input }) => {
    let query = this.state.query;
    if (input.value && /^\d+/.test(input.value[0])) {
      query = this.formatNumber(input.value);
    } else query = input.value;
    this.setState({ query, search: false, page: 1 });
  };

  handleFilterChange = ({ currentTarget: input }) => {
    let filter = input.value;
    this.setState({ filter, search: false, page: 1 });
    this.handleSubmit(filter);
  };

  handleSortChange = ({ currentTarget: input }) => {
    let sort = Number(input.value);
    this.setState({ sort, page: 1 });
    this.handleSubmit(undefined, sort);
  };

  // sortData = (data, sort) =>{
  //   if(this.state.sort || this.state.sort === 0){
  //     switch (sort) {
  //       case 2: // A-Z FirstName
  //       console.log(3)
  //         return data.sort((a, b) => a.ResFirstName.localeCompare(b.ResFirstName));
  //       case 3: // Z-A FirstName
  //       console.log(4)
  //         return data.sort((a, b) => b.ResFirstName.localeCompare(a.ResFirstName));
  //       case 4: // A-Z LastName
  //       console.log(3)
  //         return data.sort((a, b) => a.ResLastName.localeCompare(b.ResLastName));
  //       case 5: // Z-A LastName
  //       console.log(4)
  //         return data.sort((a, b) => b.ResLastName.localeCompare(a.ResLastName));
  //       default:
  //         console.log(5)
  //         return data;
  //     }
  //   }else{
  //     return data
  //   }
  // }

  handleSubmit = async (filter, sort) => {
    try {
      let { data } = await findResident(
        this.state.query,
        filter ? filter : this.state.filter,
        sort ? sort : this.state.sort,
        this.state.page
      );
      console.log(data.res.res);
      // if (this.state.data.ssn && !this.state.data.name) {
      //   console.log("filter");
      //   data = data.filter((res) => res.IsActive !== true);
      // }
      data.res.forEach((resident, index) => {
        let adms = [];
        data.adm.forEach((add) => {
          // if(add.ResID === resident.ResID) count = count+1
          if (add.ResID === resident.ResID) {
            adms.push(add);
          }
        });
        if (adms.length > 0) {
          let adm = adms[0];
          let days = getDaysBetweenDates(
            adm.GuestInDate ? adm.GuestInDate : new Date(),
            adm.DateOut ? adm.DateOut : new Date()
          );
          data.res[index].daysInProgram = days;
          data.res[index].GuestInDate = adm.GuestInDate;
        } else {
          data.res[index].daysInProgram = 0;
        }
        data.res[index].admissionCount = data.admCount[resident.ResID];
        let infs = [];
        data?.inf?.forEach((inf) => {
          // if(inf.ResID === resident.ResID) count = count+1
          if (inf.ResID === resident.ResID) {
            infs.push(inf);
          }
        });
        if (infs.length > 0) {
          let infFilter = infs.filter((inf) => checkDate(inf.Date));
          let infCount = infFilter.reduce((acc, val) => acc + val.Points, 0);
          console.log(infCount);
          data.res[index].infractionPoints = infCount;
        } else {
          data.res[index].infractionPoints = 0;
        }
      });
      this.setState({
        res: data.res,
        adm: data.adm,
        search: true,
        noMorePages: data.length < 20,
        totalRecords: data.totalRecords,
      });
    } catch (ex) {
      console.log(ex);
      // toast.error(ex.message);
    }
  };

  handleReset = async () => {
    try {
      let { data } = await findResident();
      data.res.forEach((resident, index) => {
        let adms = [];
        data.adm.forEach((add) => {
          // if(add.ResID === resident.ResID) count = count+1
          if (add.ResID === resident.ResID) {
            adms.push(add);
          }
        });
        if (adms.length > 0) {
          let adm = adms[0];
          let days = getDaysBetweenDates(
            adm.GuestInDate ? adm.GuestInDate : new Date(),
            adm.DateOut ? adm.DateOut : new Date()
          );
          data.res[index].daysInProgram = days;
          data.res[index].GuestInDate = adm.GuestInDate;
        } else {
          data.res[index].daysInProgram = 0;
        }
        data.res[index].admissionCount = data.admCount[resident.ResID];
        let infs = [];
        data?.inf?.forEach((inf) => {
          // if(inf.ResID === resident.ResID) count = count+1
          if (inf.ResID === resident.ResID) {
            infs.push(inf);
          }
        });
        if (infs.length > 0) {
          let infFilter = infs.filter((inf) => checkDate(inf.Date));
          let infCount = infFilter.reduce((acc, val) => acc + val.Points, 0);
          console.log(infCount);
          data.res[index].infractionPoints = infCount;
        } else {
          data.res[index].infractionPoints = 0;
        }
      });
      this.setState({
        res: data.res,
        adm: data.adm,
        search: false,
        query: "",
        sort: 2,
        page: 1,
        noMorePages: data.length < 20,
        totalRecords: data.totalRecords,
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  loadMore = async () => {
    try {
      let { data } = await findResident(
        this.state.query,
        this.state.filter,
        this.state.sort,
        this.state.page + 1
      );
      // if (this.state.data.ssn && !this.state.data.name) {
      //   console.log("filter");
      //   data = data.filter((res) => res.IsActive !== true);
      // }
      let adms = [];
      data.res.forEach((resident, index) => {
        let adms = [];
        data.adm.forEach((add) => {
          // if(add.ResID === resident.ResID) count = count+1
          if (add.ResID === resident.ResID) {
            adms.push(add);
          }
        });
        if (adms.length > 0) {
          let adm = adms[0];
          let days = getDaysBetweenDates(
            adm.GuestInDate ? adm.GuestInDate : new Date(),
            adm.DateOut ? adm.DateOut : new Date()
          );
          data.res[index].daysInProgram = days;
          data.res[index].GuestInDate = adm.GuestInDate;
        } else {
          data.res[index].daysInProgram = 0;
        }
        data.res[index].admissionCount = data.admCount[resident.ResID];
        let infs = [];
        data?.inf?.forEach((inf) => {
          // if(inf.ResID === resident.ResID) count = count+1
          if (inf.ResID === resident.ResID) {
            infs.push(inf);
          }
        });
        if (infs.length > 0) {
          let infFilter = infs.filter((inf) => checkDate(inf.Date));
          let infCount = infFilter.reduce((acc, val) => acc + val.Points, 0);
          console.log(infCount);
          data.res[index].infractionPoints = infCount;
        } else {
          data.res[index].infractionPoints = 0;
        }
      });
      this.setState({
        res: [...this.state.res, ...data.res],
        adm: [...this.state.adm, ...data.adm],
        search: true,
        page: this.state.page + 1,
        noMorePages: data.length < 20,
        totalRecords: data.totalRecords,
      });
    } catch (ex) {
      console.log(ex);
      // toast.error(ex.message);
    }
  };

  handleAdmissionRedirect = async () => {};

  decideAdmission = () => {
    let data1 = this.state.res.filter((res) => res.IsActive !== true);
    if (data1.length > 0) {
      return (
        <div className="findResident-Container-resultSection-Action">
          <div className="findResident-Container-resultSection-Action-cases">
            <i className="fa fa-user fa-4x primary" aria-hidden="true" />
            <div className="findResident-Container-resultSection-Action-Found-text">
              <h4>{`${this.state.res[0].ResFirstName} ${this.state.res[0].ResLastName}`}</h4>
              <h4 className="primary">{`${this.state.res[0].SSN}`}</h4>
            </div>
            <Link
              to={`/dashboard/create-admission/${this.state.res[0].ResID}`}
              className="nav-item"
            >
              <div className="sideBar-Sections-Nav-Item">
                <h4 className="primary">Create Admission Record</h4>
              </div>
            </Link>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  render() {
    return (
      <div className="findResident-Container">
        <div className="findResident-Container-searchSection">
          {/* <div className="findResident-Container-searchSection-item"> */}
          {/* @ts-ignore */}
          <InputSearch
            type={"text"}
            onChange={this.handleChangeSearch}
            name={"query"}
            label={"Search SSN/Name"}
            value={this.state.query}
          />
          <div className="findResidentButtons">
            <button className="bFlex" onClick={() => this.handleSubmit()}>
              Search
            </button>
            <button
              className="bFlex leftMargin blackButton"
              onClick={this.handleReset}
            >
              Reset
            </button>
          </div>
          <div className="grow1 filterDropdown">
            <Select
              label="Resident Status"
              options={[
                { name: "All", value: 1 },
                { name: "Active", value: 2 },
                { name: "Inactive", value: 3 },
              ]}
              name="Status"
              error={null}
              onChange={this.handleFilterChange}
              value={this.state.filter}
              noClear
            />
          </div>
          <div className="grow2 filterDropdown">
            <Select
              label="Sort"
              options={[
                { name: "Recent Guest In Date", value: 2 },
                { name: "Last Modified", value: 6 },
                { name: "Days in Program", value: 8 },
                { name: "First Name A-Z", value: 7 },
                { name: "First Name Z-A", value: 3 },
                { name: "Last Name A-Z", value: 4 },
                { name: "Last Name Z-A", value: 5 },
              ]}
              name="Sort"
              error={null}
              onChange={this.handleSortChange}
              value={this.state.sort}
              noClear
            />
          </div>
        </div>
        {this.state.totalRecords && (
          <div className="findResident-Container-resultSection">
            <h3 className="primary">{`${this.state.totalRecords} ${
              this.state.totalRecords === 1 ? "Record Found" : "Records Found"
            }`}</h3>
          </div>
        )}
        {this.state.search && this.state.res.length === 0 && (
          <div className="Resident-NotFound-container">
            <i className="fa fa-user fa-4x primary" aria-hidden="true" />
            <div className="">
              <h4>Add a resident</h4>
            </div>
            <Link to="/dashboard/create-resident">
              <button className="b ">create</button>
            </Link>
          </div>
        )}

        {this.state.res.length > 0 && (
          <div className="findResident-Container-data-bold">
            <div id={uniqId()} className="findResident-Container-data-header">
              <div className="findResident-Container-data-Item-ind grow2 bold">
                Name
              </div>
              <div className="findResident-Container-data-Item-ind grow1 center bold">
                Status
              </div>
              <div className="findResident-Container-data-Item-ind grow1 center bold">
                Phase
              </div>
              <div className="findResident-Container-data-Item-ind grow1 center bold">
                No. of stays
              </div>
              <div className="findResident-Container-data-Item-ind grow1 center bold">
                Days in Program
              </div>
              <div className="findResident-Container-data-Item-ind grow1 center bold">
                Guest in Date
              </div>
              <div className="findResident-Container-data-Item-ind grow1 center bold">
                Infraction Points
              </div>
              <div className="findResident-Container-data-Item-ind grow1" />
            </div>
          </div>
        )}
        <div className="findResident-scrollable-container">
          <div className="findResident-scrollable">
            {this.state.res.length > 0 && (
              <div className="findResident-Container-data">
                {this.state.res.map((res) => (
                  <Link
                    to={`/dashboard/resident/${res.ResID}`}
                    className="nav-item w-full text-black"
                  >
                    <div
                      id={uniqId()}
                      className="findResident-Container-data-Item"
                    >
                      <div className="findResident-Container-data-Item-ind grow2">
                        {res.ResLastName || res.ResFirstName
                          ? (res.ResFirstName ? res.ResFirstName : "") +
                            (res.ResLastName ? " " + res.ResLastName : "")
                          : "No Name"}
                      </div>
                      {res.IsActive ? (
                        <div className="findResident-Container-data-Item-ind grow1 center">
                          <div className="residentView-activeBadge grow1">
                            Active
                          </div>
                        </div>
                      ) : (
                        <div className="findResident-Container-data-Item-ind grow1 center">
                          Inactive
                        </div>
                      )}
                      {res.IsActive ? (
                        <div className="findResident-Container-data-Item-ind grow1 center">
                          {res.RecentPhase}
                        </div>
                      ) : (
                        <div className="findResident-Container-data-Item-ind grow1 center">
                          -
                        </div>
                      )}
                      <div className="findResident-Container-data-Item-ind grow1 center">
                        {res.admissionCount ? res.admissionCount : "-"}
                      </div>
                      <div className="findResident-Container-data-Item-ind grow1 center">
                        {res.daysInProgram ? res.daysInProgram : "-"}
                      </div>
                      <div className="findResident-Container-data-Item-ind grow1 center">
                        {res.GuestInDate ? dateShower(res.GuestInDate) : "-"}
                      </div>
                      <div className="findResident-Container-data-Item-ind grow1 center">
                        {res.infractionPoints ? res.infractionPoints : "0"}
                      </div>
                      <div className="findResident-Container-data-Item-ind grow1 flex-end">
                        <CiCircleChevRight size={36} color="black" />
                      </div>
                      {/* {!res.IsActive && res.ResID ? (
                  <div className="findResident-Container-data-Item-ind grow1 flexEnd">
                    <Link
                      to={`/dashboard/create-admission/${res.ResID}`}
                      className="nav-item"
                    >
                      <button className="resident-button">
                        Create Admission
                      </button>
                    </Link>
                  </div>
                ) : (
                  // <div className="findResident-Container-data-Item-ind grow2 flexEnd"></div>
                  <div className="findResident-Container-data-Item-ind grow1 flexEnd">
                    <Link
                      to={`/dashboard/exit/${res.ResID}`}
                      className="nav-item"
                    >
                      <button className="exit-button">Exit Resident</button>
                    </Link>
                  </div>
                )} */}
                      {/* {res.IsActive && res.ResID && (
                  <div className="findResident-Container-data-Item-ind grow1 flexEnd">
                    <Link
                      to={`/dashboard/update-resident/${res.ResID}`}
                      className="nav-item"
                    >
                      <button className="update-button " onClick={() => {}}>
                        Update
                      </button>
                    </Link>
                  </div>
                )} */}
                    </div>
                  </Link>
                ))}
                {!this.state.noMorePages && (
                  <div style={{ paddingTop: 10 }}>
                    <button className="b" onClick={this.loadMore}>
                      Load More
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FindResident;
