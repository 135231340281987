import Date1 from "../../components/common/date";
import React from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import {
  getActiveResidents,
  getDischargeLocationData,
  getGuestInDateData,
} from "../../services/residentService";
import { getCurrentUser } from "../../services/authService";
import { level2Access } from "../../utils/roles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { differenceInDays, parse, parseISO } from "date-fns";
function parseAnyDate(date) {
  const parsedDate = new Date(date);

  // If the date is already a Date object, return it directly
  if (date instanceof Date) {
    return date;
  }

  // If the date is an ISO string or a format recognized by the Date constructor
  else if (!isNaN(parsedDate)) {
    return parsedDate;
  }

  // Array of custom date formats to attempt parsing
  const formats = [
    "yyyy-MM-dd",
    "MM/dd/yyyy",
    "dd/MM/yyyy",
    "yyyy/MM/dd",
    "MM-dd-yyyy",
    "dd-MM-yyyy",
    "yyyy.MM.dd",
    "MM.dd.yyyy",
    "dd.MM.yyyy",
  ];

  // Attempt to parse the date using custom formats
  return parseISO(date);
}
function Reports(props) {
  const user = getCurrentUser();
  let minusoneMonth = new Date();
  minusoneMonth.setDate(minusoneMonth.getDate() - 30);
  const [DL_Dates, setDL_Dates] = useState({
    startDate: minusoneMonth,
    endDate: new Date(),
  });
  const [GID_Dates, setGID_Dates] = useState({
    startDate: minusoneMonth,
    endDate: new Date(),
  });

  const [reports, setReports] = useState({
    RbN: {
      data: [],
      created: false,
      fileName: "Resident-by-Name.pdf",
    },
    RbP: {
      data: [],
      created: false,
      fileName: "Resident-by-Phase.pdf",
    },
    RbR: {
      data: [],
      created: false,
      fileName: "Resident-by-Room.pdf",
    },
    DLR: {
      data: [],
      created: false,
      fileName: "Discharge-Location-Report.csv",
    },
    GID: {
      data: [],
      created: false,
      fileName: "Guest-In-Date-Report.csv",
    },
  });
  const [message, setMessage] = useState();

  const residentByName = async () => {
    setMessage(undefined);
    try {
      let JSONData = await getData();
      JSONData.sort((a, b) =>
        a.Name.toLowerCase() > b.Name.toLowerCase()
          ? 1
          : b.Name.toLowerCase() > a.Name.toLowerCase()
          ? -1
          : 0
      );
      let updatedReports = { ...reports };
      updatedReports.RbN.data = JSONData;
      updatedReports.RbN.created = true;
      setReports(updatedReports);
    } catch (error) {
      //@ts-ignore
      setMessage("Report could not be generated! Try again later");
    }
  };

  const residentByPhase = async () => {
    setMessage(undefined);
    try {
      let JSONData = await getData();
      JSONData.sort((a, b) =>
        a.Phase > b.Phase ? 1 : b.Phase > a.Phase ? -1 : 0
      );
      let updatedReports = { ...reports };
      updatedReports.RbP.data = JSONData;
      updatedReports.RbP.created = true;
      setReports(updatedReports);
    } catch (error) {
      //@ts-ignore
      setMessage("Report could not be generated! Try again later");
    }
  };

  const residentByRoom = async () => {
    setMessage(undefined);
    try {
      let JSONData = await getData();
      JSONData.sort((a, b) =>
        a.RoomNum > b.RoomNum ? 1 : b.RoomNum > a.RoomNum ? -1 : 0
      );
      let updatedReports = { ...reports };
      updatedReports.RbR.data = JSONData;
      updatedReports.RbR.created = true;
      setReports(updatedReports);
    } catch (error) {
      //@ts-ignore
      setMessage("Report could not be generated! Try again later");
    }
  };

  const createDLReport = async () => {
    setMessage(undefined);
    try {
      let JSONData = await getDischargeLocationData(
        DL_Dates.startDate,
        DL_Dates.endDate
      );
      if (JSONData.data?.length === 0) {
        //@ts-ignore
        setMessage("Not records found in this period");
      } else {
        console.log(JSONData);
        let updatedReports = { ...reports };
        updatedReports.DLR.data = JSONData.data;
        updatedReports.DLR.created = true;
        setReports(updatedReports);
      }
    } catch (error) {
      //@ts-ignore
      setMessage("Report could not be generated! Try again later");
    }
  };
  function getDaysBetweenDates(date1, date2) {
    const parsedDate1 = parseAnyDate(date1);
    const parsedDate2 = parseAnyDate(date2);
    return Math.abs(differenceInDays(parsedDate2, parsedDate1));
  }

  function dateShower(date) {
    if (date) {
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }
  const createGIDReport = async () => {
    setMessage(undefined);
    try {
      let JSONData = await getGuestInDateData(
        GID_Dates.startDate,
        GID_Dates.endDate
      );
      if (JSONData.data?.length === 0) {
        //@ts-ignore
        setMessage("Not records found in this period");
      } else {
        JSONData.data = JSONData.data.map((item) => {
          item.DateOfBirth = dateShower(item?.DateOfBirth);
          item.DateOut = dateShower(item?.DateOut);
          let data = {
            "Resident ID": item.ResID,
            "Full Name": item.FirstName + " " + item.LastName,
            DOB: item.DateOfBirth,
            SSN: item.SSN,
            "Admitted From": item.AdmittedFrom,
            "Days In Program": getDaysBetweenDates(
              item.GuestInDate ? item.GuestInDate : new Date(),
              item.DateOut ? item.DateOut : new Date()
            ),
            "Room No.": item.RoomNumber,
            "Move out Date": item.DateOut,
          };
          return data;
        });
        console.log(JSONData);
        let updatedReports = { ...reports };
        updatedReports.GID.data = JSONData.data;
        updatedReports.GID.created = true;
        setReports(updatedReports);
      }
    } catch (error) {
      //@ts-ignore
      setMessage("Report could not be generated! Try again later");
    }
  };
  const getData = async (type) => {
    let result = await getActiveResidents();
    let JSONData = result.data.recordset;
    let trimmedData = JSONData.map((res) => ({
      Name: `${res.ResLastName ? res.ResLastName : ""} ${
        res.ResFirstName ? res.ResFirstName : ""
      }`,
      RoomNum: res.RoomNum ? res.RoomNum : undefined,
      Phase: res.RecentPhase ? res.RecentPhase : undefined,
      LastEntryDate: res.LastEntryDate,
      DaysHere: res.LastEntryDate
        ? daysBetweenDates(res.LastEntryDate)
        : undefined,
    }));
    trimmedData.forEach((res, i) => {
      let date = new Date(trimmedData[i].LastEntryDate);
      trimmedData[i].LastEntryDate =
        date.toLocaleString("en-US", { timeZone: "CST" }) + " (CST)";
    });
    return trimmedData;
  };

  const DL_startDate_change = (date) => {
    const tempDL = { ...DL_Dates };
    tempDL.startDate = date;
    setDL_Dates(tempDL);
  };
  const DL_endDate_change = (date) => {
    const tempDL = { ...DL_Dates };
    tempDL.endDate = date;
    setDL_Dates(tempDL);
  };

  const GID_startDate_change = (date) => {
    const tempDL = { ...GID_Dates };
    tempDL.startDate = date;
    setGID_Dates(tempDL);
  };
  const GID_endDate_change = (date) => {
    const tempDL = { ...GID_Dates };
    tempDL.endDate = date;
    setGID_Dates(tempDL);
  };
  const generatePDF = (fileName, arr) => {
    const doc = new jsPDF();

    // Add title and logo
    doc.setFontSize(18);

    // Load an image from the public URL (make sure the image is in your public folder)
    const img = new Image();
    img.src = "/metro-hope.jpg"; // Replace with your actual logo image path
    img.onload = () => {
      doc.addImage(img, "PNG", 15, 15, 40, 40); // Position and size of the logo

      // Add text to the PDF
      doc.setFontSize(12);
      doc.text("Vespers", 100, 40);

      // Add the legend
      doc.setFontSize(10);
      doc.text("P = Pass", 160, 30);
      doc.text("E = Excused", 160, 35);
      doc.text("H = Min Late", 160, 40);
      doc.text("A = Absent", 160, 45);
      doc.text("S = Sick", 160, 50);

      // Define table columns
      const columns = [
        { header: "Name", dataKey: "name" },
        { header: "Tue", dataKey: "tue" },
        { header: "Wed", dataKey: "wed" },
        { header: "Thu", dataKey: "thu" },
        { header: "Fri", dataKey: "fri" },
        { header: "Sat", dataKey: "sat" },
        { header: "Sun", dataKey: "sun" },
        { header: "Mon", dataKey: "mon" },
      ];

      // Map your array to rows for the table
      const rows = arr.map((person) => ({
        name: person.Name,
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
        sun: "",
        mon: "",
      }));

      // Add the table to the PDF
      doc.autoTable({
        columns,
        body: rows,
        startY: 60,
        theme: "grid",
        styles: {
          fontSize: 10,
          cellPadding: 2,
          halign: "left",
          textColor: "#222",
        },
        headStyles: { fillColor: [100, 100, 100], textColor: [255, 255, 255] },
      });

      // Save the PDF and trigger the download
      doc.save(fileName);
    };
  };
  const daysBetweenDates = (start) => {
    const date1 = new Date(start);
    const date2 = new Date();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays + 1;
  };
  const AppKey = "pamhzojpeq49ubv";

  return (
    <div className="reports-Container">
      <div className="createResident-Container-headSection">
        <h2 className="primary">Reports</h2>
      </div>
      <div className="reports-Container-Section">
        <div className="reports-Container-Section-Individual">
          <div className="text-teal-700 text-2xl pb-4">
            {reports.RbN?.data?.length > 0 &&
              reports.RbN?.data?.length + " Records Found"}
          </div>
          <div className="reports-Container-Section-Individual-Text">
            Get Residents by Name
          </div>
          <button className="report-button button" onClick={residentByName}>
            Generate
          </button>
          {reports.RbN.created && (
            // <CSVLink data={reports.RbN.data} filename={reports.RbN.fileName}>
            <div
              className="reports-csv-button report-button button cursor-pointer"
              onClick={() =>
                generatePDF(reports.RbN.fileName, reports.RbN.data)
              }
            >
              Download
            </div>
            // </CSVLink>
          )}
        </div>
        <div className="reports-Container-Section-Individual">
          <div className="text-teal-700 text-2xl pb-4">
            {reports.RbP?.data?.length > 0 &&
              reports.RbP?.data?.length + " Records Found"}
          </div>
          <div className="reports-Container-Section-Individual-Text">
            Get Residents by Phase
          </div>
          <button className="report-button button" onClick={residentByPhase}>
            Generate
          </button>
          {reports.RbP.created && (
            <div
              className="reports-csv-button report-button button cursor-pointer"
              onClick={() =>
                generatePDF(reports.RbP.fileName, reports.RbP.data)
              }
            >
              Download
            </div>
          )}
        </div>
        <div className="reports-Container-Section-Individual">
          <div className="text-teal-700 text-2xl pb-4">
            {reports.RbR?.data?.length > 0 &&
              reports.RbR?.data?.length + " Records Found"}
          </div>
          <div className="reports-Container-Section-Individual-Text">
            Get Residents by Room
          </div>
          <button className="report-button button" onClick={residentByRoom}>
            Generate
          </button>
          {reports.RbR.created && (
            <div
              className="reports-csv-button report-button button cursor-pointer"
              onClick={() =>
                generatePDF(reports.RbR.fileName, reports.RbR.data)
              }
            >
              Download
            </div>
          )}
        </div>
      </div>

      {level2Access(user) && (
        <div className="reports-Container-Section">
          <div className="reports-Container-Section-Individual">
            <div className="text-teal-700 text-2xl pb-4">
              {reports.GID?.data?.length > 0 &&
                reports.GID?.data?.length + " Records Found"}
            </div>
            <div className="reports-Container-Section-Individual-Text">
              Guest In Date Report
            </div>
            <div className="reports-Container-Section-Individual-Dates">
              <Date1
                onChange={(value) => GID_startDate_change(value)}
                name={"startDate"}
                label={"From"}
                value={GID_Dates?.startDate ? GID_Dates.startDate : null}
              />
              <Date1
                onChange={(value) => GID_endDate_change(value)}
                name={"endDate"}
                label={"To"}
                value={GID_Dates?.endDate ? GID_Dates.endDate : null}
              />
            </div>
            <button className="report-button button" onClick={createGIDReport}>
              Generate
            </button>
            {reports.GID.created && (
              <CSVLink data={reports.GID.data} filename={reports.GID.fileName}>
                <div className="reports-csv-button report-button button">
                  Download
                </div>
              </CSVLink>
            )}
          </div>
        </div>
      )}
      {level2Access(user) && (
        <div className="reports-Container-Section">
          <div className="reports-Container-Section-Individual">
            <div className="text-teal-700 text-2xl pb-4">
              {reports.DLR?.data?.length > 0 &&
                reports.DLR?.data?.length + " Records Found"}
            </div>
            <div className="reports-Container-Section-Individual-Text">
              Discharge Location Report
            </div>
            <div className="reports-Container-Section-Individual-Dates">
              <Date1
                onChange={(value) => DL_startDate_change(value)}
                name={"startDate"}
                label={"From"}
                value={DL_Dates?.startDate ? DL_Dates.startDate : null}
              />
              <Date1
                onChange={(value) => DL_endDate_change(value)}
                name={"endDate"}
                label={"To"}
                value={DL_Dates?.endDate ? DL_Dates.endDate : null}
              />
            </div>
            <button className="report-button button" onClick={createDLReport}>
              Generate
            </button>
            {reports.DLR.created && (
              <CSVLink data={reports.DLR.data} filename={reports.DLR.fileName}>
                <div className="reports-csv-button report-button button">
                  Download
                </div>
              </CSVLink>
            )}
          </div>
        </div>
      )}
      {message && <div className="updateResident-footer">{message}</div>}
    </div>
  );
}

export default Reports;
