import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import auth from "../services/authService";
import SideBar from "./common/sideBar";
import CreateAdmission from "./dashboardPages/createAdmission";
import CreateResident from "./dashboardPages/createResident";
import ExitResident from "./dashboardPages/exitResident";
import FindResident from "./dashboardPages/findResident";
import UpdateResident from "./dashboardPages/updateResident";
import ResidentView from "./dashboardPages/residentView";
import Reports from "./dashboardPages/reports";
import TopBar from "./common/topBar";
import UserManagement from "./dashboardPages/userManagement";
import ManageUser from "./dashboardPages/manageUser";
import MyProfile from "./dashboardPages/myProfile";
import InviteUser from "./dashboardPages/inviteUser";
import CheckIn from "./dashboardPages/checkIn";
import ExitGuest from "./dashboardPages/exitGuest";
import AdmissionRecord from "./dashboardPages/AdmissionRecord";
import InfractionManagement from "./dashboardPages/infractionManagement";

const Dashboard = () => {
  const [user, setUser] = useState({
    firstName: "",
    email: "",
    lastName: "",
  });

  useEffect(() => {
    const user = auth.getCurrentUser();
    setUser(user);
  }, []);
  const location = useLocation();

  // Define the base paths where the TopBar should be displayed
  const topBarRoutes = [
    "/dashboard/create-resident",
    // "/dashboard/resident",
    // "/dashboard/create-admission",
    // "/dashboard/admission-record",
    "/dashboard/exit-guest",
    // "/dashboard/exit",
    // "/dashboard/update-resident",
    "/dashboard/reports",
    "/dashboard/user-management",
    "/dashboard/infraction-management",
    // "/dashboard/manage-user",
    "/dashboard/myProfile",
    "/dashboard/invite-user",
    "/dashboard/checkin",
    "/dashboard",
    "/dashboard/",
  ];

  console.log(location);

  // Check if the current path starts with any of the defined base paths
  const shouldShowTopBar = topBarRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <div className="dashboard">
      <div className="dashboard-sideBar">
        <SideBar user={user} />
      </div>
      <div className="dashboard-main-routes">
        {/* Conditionally render TopBar based on the current path */}
        {shouldShowTopBar && topBarRoutes.includes(location.pathname) ? (
          <TopBar user={user} />
        ) : (
          <></>
        )}
        <div className="dashboard-main-Scrollable">
          <Switch>
            <Route
              path="/dashboard/create-resident"
              component={CreateResident}
            />
            <Route path="/dashboard/resident/:id?" component={ResidentView} />
            <Route
              path="/dashboard/create-admission/:id?"
              component={CreateAdmission}
            />
            <Route
              path="/dashboard/admission-record/:id?"
              component={AdmissionRecord}
            />
            <Route path="/dashboard/exit-guest" component={ExitGuest} />
            <Route path="/dashboard/exit/:id?" component={ExitResident} />
            <Route
              path="/dashboard/update-resident/:id?"
              component={UpdateResident}
            />
            <Route path="/dashboard/reports" component={Reports} />
            <Route
              path="/dashboard/user-management"
              component={UserManagement}
            />
            <Route
              path="/dashboard/infraction-management"
              component={InfractionManagement}
            />
            <Route path="/dashboard/manage-user/:id?" component={ManageUser} />
            <Route path="/dashboard/myProfile" component={MyProfile} />
            <Route path="/dashboard/invite-user" component={InviteUser} />
            <Route path="/dashboard/checkin" component={CheckIn} />
            <Route path="/dashboard/" component={FindResident} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
