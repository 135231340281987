import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import uniqId from "uniqid";

import NoDataBox from "../common/noDataBox";
import InputFieldLayoutRow from "../common/inputFieldLayoutRow";
import {
  createInfractions,
  deleteInfraction,
  getAllInfractions,
  updateInfraction,
} from "../../services/infractionService";
import { toast } from "react-toastify";

function InfractionManagement(props) {
  const [infractions, setInfractions] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [newInfraction, setNewInfraction] = useState({
    ID: "",
    Infraction: "",
    Points: "",
    Dismissal: "", // Changed initial state to empty string
  });
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const res = await getAllInfractions();
      setInfractions(res.data);
    }
    fetchData();
  }, []);

  // Handle Edit Infraction
  const handleEdit = (id) => {
    const infractionToEdit = infractions.find(
      (infraction) => infraction.ID === id
    );
    setNewInfraction(infractionToEdit);
    setShowForm(true);
    setIsEditing(true);
    setEditId(id);
  };

  // Handle Delete Infraction
  const handleDelete = async (id) => {
    const res = await deleteInfraction(id);
    if (res) fetchData();
    toast.success("Infraction deleted successfully");
  };

  // Handle Form Change
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "Points") {
      // Only allow numeric values
      if (value === "" || /^[0-9]+$/.test(value)) {
        setNewInfraction((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (name === "Dismissal") {
      // Set Dismissal to 'Y' if checked, else ''
      setNewInfraction((prevState) => ({
        ...prevState,
        [name]: checked ? "Y" : "",
      }));
    } else {
      setNewInfraction((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  async function fetchData() {
    const res = await getAllInfractions();
    setInfractions(res.data);
  }

  // Handle Form Submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (isEditing) {
      // Update existing infraction
      const res = await updateInfraction(newInfraction);
      if (res.data) fetchData();
    } else {
      const res = await createInfractions(newInfraction);
      if (res.data) fetchData();
      // Add new infraction
      // const newInfractionWithId = { ...newInfraction, ID: uniqId() };
      // setInfractions((prevInfractions) => [
      //   ...prevInfractions,
      //   newInfractionWithId,
      // ]);
    }

    setShowForm(false);
    setIsEditing(false);
    setEditId(null);
    setNewInfraction({
      ID: "",
      Infraction: "",
      Points: "",
      Dismissal: "", // Reset to empty string
    });
  };

  // Handle Cancel Form
  const handleFormCancel = () => {
    setShowForm(false);
    setIsEditing(false);
    setEditId(null);
    setNewInfraction({
      ID: "",
      Infraction: "",
      Points: "",
      Dismissal: "", // Reset to empty string
    });
  };

  return (
    <div className="reports-Container p-6">
      <div className="createResident-Container-headSection mb-4">
        <h2 className="text-3xl font-semibold text-teal-600 mb-6">
          {!showForm
            ? "Infraction Management"
            : isEditing
            ? "Update Infraction"
            : "Add Infraction"}
        </h2>
        {!showForm && (
          <button
            onClick={() => {
              setShowForm(true);
              setIsEditing(false);
              setNewInfraction({
                ID: "",
                Infraction: "",
                Points: "",
                Dismissal: "",
              });
            }}
            className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 text-lg no-underline"
          >
            Create New Infraction
          </button>
        )}
      </div>

      {showForm ? (
        <form
          onSubmit={handleFormSubmit}
          className="mb-8 max-w-lg mx-auto bg-white shadow-lg rounded-lg p-8"
        >
          <div className="mb-6">
            <label className="block text-gray-800 text-lg font-semibold mb-3">
              Infraction:
            </label>
            <input
              type="text"
              name="Infraction"
              value={newInfraction.Infraction}
              onChange={handleFormChange}
              className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 text-lg leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-800 text-lg font-semibold mb-3">
              Points:
            </label>
            <input
              type="number"
              name="Points"
              value={newInfraction.Points}
              onChange={handleFormChange}
              className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 text-lg leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-800 text-lg font-semibold mb-3">
              Dismissal:
            </label>
            <input
              type="checkbox"
              name="Dismissal"
              checked={newInfraction.Dismissal === "Y"}
              onChange={handleFormChange}
              className="mr-3 leading-tight"
            />
            <span className="text-gray-700 text-lg">Yes</span>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-teal-500 text-white font-bold px-6 py-3 rounded-lg hover:bg-teal-600 text-lg"
            >
              {isEditing ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              onClick={handleFormCancel}
              className="bg-gray-500 text-white font-bold px-6 py-3 rounded-lg hover:bg-gray-600 text-lg"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 shadow-md rounded text-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-700 uppercase text-lg leading-normal">
                {/* <th className="py-3 px-6 text-left">ID</th> */}
                <th className="py-3 px-6 text-left">Infraction</th>
                <th className="py-3 px-6 text-left">Points</th>
                <th className="py-3 px-6 text-left">Dismissal</th>
                <th className="py-3 px-6 text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-lg font-normal">
              {infractions.length > 0 ? (
                infractions
                  .sort((a, b) => a.Infraction.localeCompare(b.Infraction))
                  .map((infraction) => (
                    <tr
                      key={infraction.ID}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      {/* <td className="py-3 px-6 text-left whitespace-nowrap">
                      {infraction.ID}
                    </td> */}
                      <td className="py-3 px-6 text-left">
                        {infraction.Infraction}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {infraction.Points}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {infraction.Dismissal}
                      </td>
                      <td className="py-3 px-6 text-right">
                        <button
                          onClick={() => handleEdit(infraction.ID)}
                          className="bg-teal-500 text-white px-3 py-2 rounded hover:bg-teal-600 mr-2 text-lg"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(infraction.ID)}
                          className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600 text-lg"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={5} className="py-3 px-6 text-center">
                    No infractions available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default InfractionManagement;
